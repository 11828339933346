import React, { useEffect, useState } from "react";
import { getDocument } from "pdfjs-dist";
import "./pdfMulti.css";
import PDFFullView from "./PDFFullView";
import { Link16Filled } from "@fluentui/react-icons";

const PDFMulti = ({ pdfDetails, onUpdateReferences }) => {
  const [prevPdfUrl, setPrevPdfUrl] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [thumbnails, setThumbnails] = useState([]);
  const [isFullView, setIsFullView] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const currentUrl = pdfDetails?.pdfUrl;
    if (!currentUrl) return;

    // Has the URL changed?
    if (currentUrl !== prevPdfUrl) {
      // 1. Reset for new PDF
      setPrevPdfUrl(currentUrl);
      setPdf(null);
      setThumbnails([]);
      setIsFullView(false);
      setPageNum(1);
      onUpdateReferences(null, [], false);

      // 2. Start loading new PDF
      setIsLoading(true);
      const generateThumbnails = async () => {
        try {
          const loadedPdf = await getDocument(currentUrl).promise;
          setPdf(loadedPdf);
          setTotalPages(loadedPdf.numPages);

          const tempThumbnails = [];
          for (let i = 1; i <= loadedPdf.numPages; i++) {
            const page = await loadedPdf.getPage(i);
            const viewport = page.getViewport({ scale: 0.5 });
            const canvas = document.createElement("canvas");
            canvas.width = viewport.width;
            canvas.height = viewport.height;
            const context = canvas.getContext("2d");
            await page.render({ canvasContext: context, viewport }).promise;

            tempThumbnails.push({
              pageNumber: i,
              thumbnail: canvas.toDataURL("image/png"),
              references: pdfDetails.references_task_path?.[i]?.length || 0,
            });
          }
          setThumbnails(tempThumbnails);
        } catch (error) {
          console.error("Error generating thumbnails:", error);
        } finally {
          // Finished loading
          setIsLoading(false);
        }
      };
      generateThumbnails();
    }
  }, [pdfDetails, prevPdfUrl, onUpdateReferences]);

  // ----------- Full View Handlers -----------
  const openFullView = (page) => {
    setPageNum(page);
    setIsFullView(true);

    // Provide reference info for the page
    const referencesForPage = pdfDetails.references_task_name?.[page] || [];
    onUpdateReferences(
      page + (pdfDetails.start_page || 1) - 1,
      referencesForPage,
      true
    );
  };

  const closeFullView = () => {
    setIsFullView(false);
    onUpdateReferences(null, [], false);
  };

  // -------------------------------------------

  // Show a "Loading" state if we are loading the new PDF
  if (isLoading) {
    return (
      <div className="pdf-multi-container">
        <p>Loading new PDF...</p>
      </div>
    );
  }

  return (
    <div className="pdf-multi-container">
      {!isFullView ? (
        <div className="pdf-thumbnails">
          {thumbnails.length > 0 ? (
            thumbnails.map((thumbnail, index) => (
              <div
                key={index}
                className="pdf-thumbnail"
                onClick={() => openFullView(thumbnail.pageNumber)}
              >
                <div className="page-info">
                  <span>PAGE {thumbnail.pageNumber + pdfDetails.start_page - 1}</span> -
                  <Link16Filled className="link-icon" />
                  <span>{thumbnail.references}</span>
                </div>
                <div
                  className="thumbnail"
                  style={{ backgroundImage: `url(${thumbnail.thumbnail})` }}
                />
              </div>
            ))
          ) : (
            // If not loading but no thumbnails, there's nothing to show
            <p>No pages found.</p>
          )}
        </div>
      ) : (
        pdf && (
          <PDFFullView
            pdf={pdf}
            pdfDetails={pdfDetails}
            pageNum={pageNum}
            totalPages={totalPages}
            onUpdateReferences={onUpdateReferences}
            closeFullView={closeFullView}
            setPageNum={setPageNum}
          />
        )
      )}
    </div>
  );
};

export default PDFMulti;
