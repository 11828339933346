import React, { useState, useEffect, useRef } from "react";
import {
  Chat28Filled,
  Add28Filled,
  Wrench20Filled,
  Bookmark20Filled,
} from "@fluentui/react-icons";
import Message from "../../components/Chat/Message";
import ChatInput from "../../components/Chat/ChatInput";
import NewQueryModal from "../../components/Chat/NewQueryModal";
import PDFMulti from "../../components/PDF/PDFMulti";
import aviationApi from "../AviationMaintenance/AviationApi";
import "./chat.css";

// 1. Import the new modal for references
import ReferencePDFModal from "../../components/PDF/ReferencePDFModal";

const ChatPage = () => {
  const [messages, setMessages] = useState([]);
  const [loadingMessageId, setLoadingMessageId] = useState(null);
  const [activeSidebar, setActiveSidebar] = useState("chat");
  const [showButtons, setShowButtons] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [tasks, setTasks] = useState([]); // Left sidebar tasks
  const [pdfDetails, setPdfDetails] = useState([]);

  // Right sidebar references
  const [currentReferences, setCurrentReferences] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [isPdfFullView, setIsPdfFullView] = useState(false);

  // 2. State for reference modal
  const [isReferenceModalOpen, setIsReferenceModalOpen] = useState(false);
  const [referencePdfUrl, setReferencePdfUrl] = useState(null);

  const chatEndRef = useRef(null);
  const [selectedRefIndex, setSelectedRefIndex] = useState(null);

  useEffect(() => {
    const initialMessages = [
      {
        id: 1,
        text: "Quickly find the tasks and procedures by typing specific fault or task codes, or describe the problem with your own words.",
        type: "system",
      },
    ];
    setMessages(initialMessages);
  }, []);

  // Auto-scroll to bottom on new messages
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Click a task on left sidebar
  const handleTaskClick = (task) => {
    setActiveSidebar(task.task_number);
    setPdfDetails(task);
  };

  // Switch to chat on left sidebar
  const handleChatClick = () => {
    setActiveSidebar("chat");
    setIsPdfFullView(false);
  };

  // Called by PDFMulti when the user enters or exits full view, or changes page
  const handleUpdateReferences = (page, references, isFullView) => {
    setCurrentPage(page || null);
    // references is now an array of { name, path, isPdf }
    setCurrentReferences(Array.isArray(references) ? references : []);
    setIsPdfFullView(isFullView);
  };


  // 3. Called when the user clicks a reference in the right sidebar
  const handleReferenceClick = async (refObj) => {
    if (!refObj.isPdf) return; // If it's not a PDF, do nothing

    console.log(refObj)
    try {
      const refUrl = await aviationApi.generateUrl(refObj.path);
      setReferencePdfUrl(refUrl);
      setIsReferenceModalOpen(true);
    } catch (error) {
      console.error("Error generating reference PDF URL:", error);
    }
  };

  // 4. Close the reference modal
  const handleCloseReferenceModal = () => {
    setIsReferenceModalOpen(false);
    setReferencePdfUrl(null);
    setSelectedRefIndex(null);
  };

  // Send user message
  const handleSendMessage = async (newMessage) => {
    const userMessage = {
      id: messages.length + 1,
      text: newMessage,
      type: "user",
    };
    setMessages((prev) => [...prev, userMessage]);

    setShowButtons(true);

    const placeholderMessageId = messages.length + 2;
    const placeholderSystemMessage = {
      id: placeholderMessageId,
      text: "",
      type: "system",
    };
    setMessages((prev) => [...prev, placeholderSystemMessage]);
    setLoadingMessageId(placeholderMessageId);

    try {
      const response = await aviationApi.ask(newMessage);

      if (response) {
        let pdfData = [];

        // If there's a fic object w/ tool_response
        if (response.if_fic?.bool && response.if_fic.tool_response) {
          pdfData = await Promise.all(
            response.if_fic.tool_response.map(async (task) => {
              const pdfUrl = await aviationApi.generatePdfUrl(
                task.filename,
                task.pages.start_page,
                task.pages.end_page
              );
              return {
                task_number: task.task_number,
                task_title: task.task_title,
                start_page: task.pages.start_page,
                end_page: task.pages.end_page,
                references_task_path: task.references_task_path,
                references_task_name: task.references_task_name,
                pdfUrl, 
              };
            })
          );
          setTasks(pdfData); 
        }

        // The final text
        const messageContent = response.if_fic?.bool
          ? { isGrid: true, tasks: pdfData, text: response.answer }
          : { isGrid: false, text: response.answer };

        setMessages((prev) =>
          prev.map((msg) =>
            msg.id === placeholderMessageId ? { ...msg, ...messageContent } : msg
          )
        );
      } else {
        // No response
        setMessages((prev) =>
          prev.map((msg) =>
            msg.id === placeholderMessageId
              ? { ...msg, text: "No response from the API." }
              : msg
          )
        );
      }
    } catch (error) {
      console.error("Error in handleSendMessage:", error);
      setMessages((prev) =>
        prev.map((msg) =>
          msg.id === placeholderMessageId
            ? { ...msg, text: "Error communicating with the API." }
            : msg
        )
      );
    } finally {
      setLoadingMessageId(null);
    }
  };

  // Clear chat history
  const handleClearHistory = () => {
    setShowModal(true);
  };

  const confirmClearHistory = () => {
    const initialMessage = [
      {
        id: 1,
        text: "Quickly find the tasks and procedures by typing specific fault or task codes, or describe the problem with your own words.",
        type: "system",
      },
    ];
    setMessages(initialMessage);
    setShowButtons(false);
    setShowModal(false);
    setActiveSidebar("chat");
    setTasks([]);
    setCurrentReferences([]);
    setIsPdfFullView(false);
  };

  const cancelClearHistory = () => {
    setShowModal(false);
  };

  return (
    <div className="chat-container">
      {/* Left Sidebar */}
      <div className="sidebar-left">
        {showButtons && (
          <div className="button-group">
            <button
              className={`chat-button ${activeSidebar === "chat" ? "active" : ""}`}
              onClick={handleChatClick}
            >
              <Chat28Filled className="icon" />
              <span>Chat</span>
            </button>
            <button className="chat-button" onClick={handleClearHistory}>
              <Add28Filled className="icon" />
              <span>New Query</span>
            </button>
            {tasks.length > 0 && (
              <>
                <h3>XXX TASKS</h3>
                {tasks.map((task, index) => (
                  <button
                    key={index}
                    className={`chat-button task-button ${
                      activeSidebar === task.task_number ? "active" : ""
                    }`}
                    onClick={() => handleTaskClick(task)}
                  >
                    <Wrench20Filled className="icon" />
                    <span>{task.task_number}</span>
                  </button>
                ))}
              </>
            )}
          </div>
        )}
      </div>

      {/* Main Chat or PDFMulti */}
      <div className="chat-main">
        {activeSidebar === "chat" ? (
          <>
            <div className="chat-window">
              {messages.map((msg) => (
                <Message
                  key={msg.id}
                  text={msg.text}
                  isGrid={msg.isGrid}
                  tasks={msg.tasks}
                  type={msg.type}
                  loading={msg.id === loadingMessageId}
                  handleTaskClick={handleTaskClick}
                />
              ))}
              <div ref={chatEndRef} />
            </div>
            <ChatInput onSendMessage={handleSendMessage} />
          </>
        ) : (
          <PDFMulti
            pdfDetails={pdfDetails}
            onUpdateReferences={handleUpdateReferences}
          />
        )}
      </div>

      {/* Right Sidebar for references */}
      {isPdfFullView && (
        <div className="sidebar-right">
          <div className="button-group">
            <div className="references-header">
              <Bookmark20Filled className="icon" />
              <span>Page {currentPage} References</span>
            </div>

            {currentReferences.length === 0 ? (
              // If no references
              <span style={{ fontWeight: "bold", color: "black" }}> No references on this page.</span>
            ) : (
              // Otherwise show the reference buttons
              currentReferences.map((ref, index) => (
                <button
                  key={index}
                  className={`chat-button ${selectedRefIndex === index ? "active" : ""}`}
                  disabled={!ref.isPdf}
                  onClick={() => {
                    setSelectedRefIndex(index);
                    handleReferenceClick(ref);
                  }}
                >
                  {ref.name}
                </button>
              ))
            )}
          </div>
        </div>
      )}


      {/* Clear history modal */}
      {showModal && (
        <NewQueryModal onConfirm={confirmClearHistory} onCancel={cancelClearHistory} />
      )}

      {/* Reference PDF Modal */}
      <ReferencePDFModal
        isOpen={isReferenceModalOpen}
        referencePath={referencePdfUrl}
        onClose={handleCloseReferenceModal}
      />
    </div>
  );
};

export default ChatPage;
