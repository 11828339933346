import React from "react";
import { useNavigate } from "react-router-dom";
import "./aviationHome.css";
import logo from "../../images/mesmerise-home-logo.png";
import buttonIcon from "../../images/home-icon-logo.png";  // Import the icon

const AviationHome = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/aviation-ai/chat");
  };

  return (
    <div className="aviation-home-container">
      <img src={logo} alt="Mesmerise Logo" className="aviation-home-logo" />
      <p className="aviation-home-text">
        Hello! I am your Aviation AI assistant,<br /> designed to streamline your workflow.
      </p>
      <p className="aviation-home-description">
        Whether you’re troubleshooting, performing routine maintenance, or completing <br /> inspections, 
        I’ll make your job easier by delivering precise, accurate information, so you <br /> can focus 
        on what you do best: 
        <span className="highlight-purple"> keeping the aircraft safe and operational.</span>
      </p>
      <button className="aviation-home-button" onClick={handleButtonClick}>
        <img src={buttonIcon} alt="Home Icon" className="button-icon" />  {/* Logo to the left */}
        Let’s find your first task
      </button>
    </div>
  );
};

export default AviationHome;
