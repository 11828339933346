import React, { useEffect, useState } from "react";
import { Bookmark24Filled } from "@fluentui/react-icons";
import "./message.css";

const Message = ({ text, type, loading = true, isGrid = false, tasks = [], handleTaskClick }) => {
  const [loadingMessage, setLoadingMessage] = useState("Processing your query");
  const loadingMessages = [
    "Processing your query",
    "Retrieving information",
    "Analyzing data",
    "Compiling results",
    "Finalizing the output"
  ];

  useEffect(() => {
    if (loading) {
      let index = 0;
      let intervalTime = 1000; // Start with 1 second intervals
      const interval = setInterval(() => {
        if (index < loadingMessages.length - 1) {
          setLoadingMessage(loadingMessages[index]);
          index++;
          intervalTime += 1000; // Increase time by 1 second
        } else {
          setLoadingMessage(loadingMessages[loadingMessages.length - 1]); // Stop at last message
          clearInterval(interval); // Stop further updates
        }
      }, intervalTime);

      return () => clearInterval(interval); // Cleanup interval on unmount
    } else {
      setLoadingMessage(""); // Clear the loading message when loading stops
    }
  }, [loading]);

  const messageClass = type === "system" ? "system-message" : "user-message";

  // Split text into three parts: before, task section (if grid), and after
  const splitTextForTasks = (text) => {
    const parts = text.split("\n\n");
    if (parts.length >= 3) {
      return {
        before: parts[0], // Text before the grid
        after: parts.slice(-1)[0], // Text after the grid
      };
    }
    return { before: text, after: "" }; // Fallback
  };

  const { before, after } = isGrid ? splitTextForTasks(text) : { before: text, after: "" };

  // Render text with bold formatting for content enclosed in **
  const renderFormattedText = (text) => {
    if (!text) return null;
    const regex = /\*\*(.*?)\*\*/g; // Match text between **

    return text.split("\n").map((line, i) => (
      <p key={i}>
        {line.split(regex).map((part, index) =>
          index % 2 === 1 ? <strong key={index}>{part}</strong> : <span key={index}>{part}</span>
        )}
      </p>
    ));
  };

  return (
    <div className="message-container">
      {type === "system" && (
        <img
          src={require("../../images/mesmerise-small-logo.png")}
          alt="System Logo"
          className="message-logo"
        />
      )}
      {loading ? (
        <div className="loading-container">
          <img
            src={require("../../images/chat-loading.gif")}
            alt="Loading"
            className="loading-gif"
            style={{ mixBlendMode: "darken" }}
          />
          <p className="loading-text">{loadingMessage}</p>
        </div>
      ) : (
        <div className={`message-bubble ${messageClass}`}>
          {/* Render the text before the grid */}
          <div className="message-text">{renderFormattedText(before)}</div>

          {/* Render the task grid if isGrid is true */}
          {isGrid && (
            <div className="tasks-grid">
              {tasks.map((task, index) => (
                <div key={index} className="task-card" onClick={() => handleTaskClick(task)}>
                  <strong>{task.task_number}</strong>
                  <p>{task.task_title}</p>
                  <div className="task-pages">
                    <Bookmark24Filled />
                    Pages: {task.start_page} - {task.end_page}
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Render the text after the grid */}
          {after && <div className="message-text">{renderFormattedText(after)}</div>}
        </div>
      )}
    </div>
  );
};

export default Message;
