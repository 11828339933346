import React, { useEffect, useRef } from "react";
import { IconButton } from "@fluentui/react";

function PDFFullView({
  pdf,               // The loaded PDF object from pdfjs-dist
  pdfDetails,        // Contains references_task_name, references_task_path, etc.
  pageNum,           // Current page number
  totalPages,        // Total pages in the PDF
  onUpdateReferences,
  closeFullView,
  setPageNum,
}) {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!pdf || !canvasRef.current) return;

    let renderTask = null;
    let isCancelled = false;

    const renderPage = async () => {
      try {
        // 1. Get the page
        const page = await pdf.getPage(pageNum);
        if (isCancelled) return;

        // 2. Set up canvas dimensions
        const viewport = page.getViewport({ scale: 1.5 });
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        // 3. Render the page into the canvas
        renderTask = page.render({ canvasContext: ctx, viewport });
        await renderTask.promise;

        if (isCancelled) return;

        // 4. Once rendering is done, update references for this page
        const refsForPage =
          pdfDetails?.references_task_name?.[pageNum] || [];
        const pathsForPage =
          pdfDetails?.references_task_path?.[pageNum] || [];

        const referencesWithStatus = refsForPage.map((refName, idx) => ({
          name: refName,
          path: pathsForPage[idx],
          isPdf: pathsForPage[idx]?.endsWith(".pdf") || false,
        }));

        onUpdateReferences(
          pageNum + (pdfDetails.start_page || 1) - 1,
          referencesWithStatus,
          true
        );
      } catch (error) {
        console.error("Error loading PDF page:", error);
      }
    };

    renderPage();

    // Cleanup if user switches pages quickly or unmounts
    return () => {
      isCancelled = true;
      if (renderTask) {
        renderTask.cancel();
      }
    };
    /**
     * NOTE: We only depend on [pdf, pageNum].
     * - If pdfDetails changes frequently in the parent, it can cause flicker.
     * - If you absolutely must handle updated references mid-page, you'll need
     *   to re-run the effect on pdfDetails changes. But that can trigger re-renders.
     */
  }, [
    pdf,
    pageNum, // re-render if the user changes page
    // remove pdfDetails & onUpdateReferences from dependencies to reduce flickers.
    // If references or callback truly change, see note below.
  ]);

  return (
    <div className="pdf-full-view">
      <canvas ref={canvasRef} className="pdf-canvas" />

      <div className="pdf-navigation">
        <IconButton
          iconProps={{ iconName: "Back" }}
          title="Back to Multi View"
          ariaLabel="Back to Multi View"
          onClick={closeFullView}
        />

        <IconButton
          iconProps={{ iconName: "ChevronLeft" }}
          title="Previous Page"
          ariaLabel="Previous Page"
          onClick={() => setPageNum((prev) => Math.max(1, prev - 1))}
          disabled={pageNum <= 1}
        />

        <span className="pdf-page-info">
          PAGE {pageNum + (pdfDetails.start_page || 1) - 1}
        </span>

        <IconButton
          iconProps={{ iconName: "ChevronRight" }}
          title="Next Page"
          ariaLabel="Next Page"
          onClick={() => setPageNum((prev) => Math.min(totalPages, prev + 1))}
          disabled={pageNum >= totalPages}
        />
      </div>
    </div>
  );
}

export default PDFFullView;
