import React, { useState, useRef, useEffect } from "react";
import { getTokenOrRefresh } from "../Speech2Text/token_util";
import * as speechsdk from "microsoft-cognitiveservices-speech-sdk";
import { ResultReason } from "microsoft-cognitiveservices-speech-sdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faArrowUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./chatInput.css";

const ChatInput = ({ onSendMessage }) => {
  const [input, setInput] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const canvasRef = useRef(null);
  const mediaStreamRef = useRef(null);
  let animationFrameId;
  let audioContext, analyser, source;

  useEffect(() => {
    if (isRecording && canvasRef.current) {
      startAudioVisualizer();
    }

    return () => {
      stopAudioStream();
      cancelAnimationFrame(animationFrameId);
      if (audioContext) {
        audioContext.close();
      }
    };
  }, [isRecording]);

  // Audio Visualizer and Stream
  const startAudioVisualizer = async () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Start the media stream for microphone access
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaStreamRef.current = stream;  // Store stream to stop it later

    audioContext = new (window.AudioContext || window.webkitAudioContext)();
    analyser = audioContext.createAnalyser();
    source = audioContext.createMediaStreamSource(stream);

    source.connect(analyser);
    analyser.fftSize = 512;

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      analyser.getByteFrequencyData(dataArray);

      ctx.beginPath();
      const barWidth = (canvas.width / bufferLength) * 2.5;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const barHeight = dataArray[i] / 1.5;
        ctx.fillStyle = "#641770";
        ctx.fillRect(x, canvas.height - barHeight, barWidth, barHeight);
        x += barWidth + 2;
      }

      animationFrameId = requestAnimationFrame(draw);
    };

    draw();
  };

  // Stop Audio Stream and Context
  const stopAudioStream = () => {
    if (mediaStreamRef.current) {
      const tracks = mediaStreamRef.current.getTracks();
      tracks.forEach((track) => track.stop());  // Stop each track in the stream
      mediaStreamRef.current = null;  // Clear the reference
    }
    if (audioContext) {
      audioContext.close();
      audioContext = null;
    }
  };

  // Speech-to-Text Handler
  const sttFromMic = async () => {
    setIsRecording(true);
    setInput("");

    const tokenObj = await getTokenOrRefresh();
    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
    speechConfig.speechRecognitionLanguage = "en-US";

    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);

    recognizer.recognizeOnceAsync((result) => {
      if (result.reason === ResultReason.RecognizedSpeech) {
        setInput(result.text);
      } else {
        setInput("");
      }
      setIsRecording(false);
      stopAudioStream();  // Stop the stream after recognition
    });
  };

  const handleClear = () => {
    setInput("");
  };

  const handleSend = () => {
    if (input.trim()) {
      onSendMessage(input);
      setInput("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  return (
    <div className="chat-input-wrapper">
      <div className="chat-input-container">
        {input && (
          <div className="clear-input-button" onClick={handleClear}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}

        <div className="input-wrapper">
          {isRecording && <canvas ref={canvasRef} className="audio-canvas" />}
          {!isRecording && (
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyPress}
              placeholder="Message Aviation AI"
              className={input ? "active-input" : ""}
            />
          )}
        </div>

        <div className="chat-input-button">
          {input ? (
            <FontAwesomeIcon icon={faArrowUp} className="send-icon" onClick={handleSend} />
          ) : (
            <FontAwesomeIcon
              icon={faMicrophone}
              className={`mic-icon ${isRecording ? "recording" : ""}`}
              onClick={sttFromMic}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatInput;
