import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import MultiNavBar from './components/MultiNavBar.js';
import Home from './pages/Home/Home.js';
import IframePage from './pages/Iframe/IframePage.js';
import MetaTags from './components/MetaTags.js'; 
import AviationMaintenance from './pages/AviationMaintenance/AviationMaintenance.js';
import AviationAI from './pages/AviationAI/AviationApp.js';
import './App.css';

const AppContent = () => {
  // Get the current location (route)
  const location = useLocation();

  return (
    <div className="app-container">
      <MetaTags />
      {/* Render MultiNavBar only if we're not on a route starting with /aviation-ai */}
      { !location.pathname.startsWith('/aviation-ai') && <MultiNavBar /> }

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aviation-maintenance" element={<AviationMaintenance />} />
        <Route path="/aviation-ai/*" element={<AviationAI />} />
        <Route path="/iframe/:title" element={<IframePage />} />
      </Routes>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
