import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"; // Import icons
import "./NewQueryModal.css";

const NewQueryModal = ({ onConfirm, onCancel }) => {
  return (
    <div className="modal-backdrop">
      <div className="new-query-modal">
        <h2>Confirm New Query</h2>
        <p>
          This will delete your current chat and start a new query. Are you sure you want to
          continue?
        </p>
        <div className="modal-actions">
          <button className="primary-button" onClick={onConfirm}>
            <FontAwesomeIcon icon={faCheck} style={{ fontSize: "16px", color: "#8ef7b4" }} /> Confirm
          </button>
          <button className="secondary-button" onClick={onCancel}>
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: "16px", color: "#862f94" }} /> Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewQueryModal;
