// ReferencePDFModal.jsx
import React, { useEffect, useRef, useState } from "react";
import { getDocument } from "pdfjs-dist";
import { IconButton } from "@fluentui/react";
import "./ReferencePDFModal.css";

function ReferencePDFModal({ isOpen, onClose, referencePath }) {
  const [pdf, setPdf] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const canvasRef = useRef(null);

  // 1) Load PDF from referencePath (URL) when the modal opens
  useEffect(() => {
    if (!isOpen || !referencePath) return;

    let isCancelled = false;

    const loadPdf = async () => {
      try {
        const loadedPdf = await getDocument(referencePath).promise;
        if (!isCancelled) {
          setPdf(loadedPdf);
          setTotalPages(loadedPdf.numPages || 1);
          setPageNum(1);
        }
      } catch (error) {
        console.error("Error loading reference PDF:", error);
      }
    };

    loadPdf();
    return () => {
      isCancelled = true;
    };
  }, [isOpen, referencePath]);

  // 2) Render current page
  useEffect(() => {
    if (!isOpen || !pdf || !canvasRef.current) return;

    let renderTask = null;
    let isCancelled = false;

    const renderPage = async () => {
      try {
        const page = await pdf.getPage(pageNum);
        if (isCancelled) return;

        const viewport = page.getViewport({ scale: 1.3 });
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        renderTask = page.render({ canvasContext: context, viewport });
        await renderTask.promise;
      } catch (err) {
        console.error("Error rendering reference PDF page:", err);
      }
    };

    renderPage();

    return () => {
      isCancelled = true;
      if (renderTask) renderTask.cancel();
    };
  }, [isOpen, pdf, pageNum]);

  // If not open, render nothing
  if (!isOpen) return null;

  return (
    <div className="reference-pdf-modal-overlay">
      <div className="reference-pdf-modal-content">
        <div className="modal-header">
          {/* "X" Icon to close */}
          <IconButton
            iconProps={{ iconName: "ChromeClose" }}
            title="Close"
            ariaLabel="Close"
            onClick={onClose}
          />
        </div>

        <div className="pdf-viewer">
          <canvas ref={canvasRef} className="pdf-canvas" />
        </div>

        <div className="pdf-navigation">
          <IconButton
            iconProps={{ iconName: "ChevronLeft" }}
            title="Previous Page"
            onClick={() => setPageNum((prev) => Math.max(1, prev - 1))}
            disabled={!pdf || pageNum <= 1}
          />
          <span className="pdf-page-info">
            Page {pageNum} of {totalPages}
          </span>
          <IconButton
            iconProps={{ iconName: "ChevronRight" }}
            title="Next Page"
            onClick={() => setPageNum((prev) => Math.min(totalPages, prev + 1))}
            disabled={!pdf || pageNum >= totalPages}
          />
        </div>
      </div>
    </div>
  );
}

export default ReferencePDFModal;
