import React from 'react';
import Navbar from "../../components/Navbar/NavBar";  // if needed
import { Routes, Route, Navigate } from 'react-router-dom';
import AviationHome from './AviationHome';
import ChatPage from './ChatPage';

const AviationAI = () => {
  return (
    <div className="aviation-ai-container">
      <Navbar title="Aviation AI" />  {/* Only if this is needed for this page */}
      
      {/* Define the Routes for the Aviation AI app */}
      <Routes>
        {/* Redirect to /aviation-ai/home */}
        <Route path="/" element={<Navigate to="/aviation-ai/home" />} />
        <Route path="/home" element={<AviationHome />} />
        <Route path="/chat" element={<ChatPage />} />
      </Routes>
    </div>
  );
};

export default AviationAI;
