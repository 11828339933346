import React from "react";
import { useNavigate } from "react-router-dom";
import "./navbar.css";

const Navbar = ({ title }) => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/aviation-ai");
  };

  return (
    <div className="custom-navbar">
      <div className="nav-logo" onClick={handleLogoClick}>
        <img
          src={require("../../images/mesmerise-small-logo.png")}
          alt="Logo"
          className="logo-img"
        />
        <div className="logo-title">{title || "Aviation AI"}</div>
      </div>

      <div className="circle-button">
        <div className="icon">...</div>
      </div>
    </div>
  );
};

export default Navbar;
