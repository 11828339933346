import axios from 'axios';

// Load environment variables
const apiUrl = process.env.REACT_APP_AVIATION_API_URL;
const apiKey = process.env.REACT_APP_AVIATION_API_KEY;

if (!apiUrl || !apiKey) {
    console.error("Error: API URL or API Key is missing. Check your .env file.");
}

class AviationApi {
    constructor() {
        this.chatHistory = [];
    }

    // Utility for logging API errors
    logApiError(error, endpoint) {
        if (error.response) {
            console.error(
                `Error: API request to ${endpoint} failed with status code ${error.response.status}`,
                error.response.data
            );
        } else {
            console.error(`Error: API request to ${endpoint} failed`, error.message);
        }
    }

    // Function to ask a question to the API
    async ask(input) {
        const endpoint = "/post_input";
        const url = `${apiUrl}${endpoint}`;
        const headers = {
            accept: 'application/json',
            'x-api-key': apiKey,
        };
        const data = {
            input: input,
            chat_history: this.chatHistory,
        };

        try {
            const response = await axios.post(url, data, { headers });
            if (response.status === 200 && response.data) {
                this.chatHistory = response.data.chat_history || [];
                return response.data;
            } else {
                console.error(`Error: Unexpected response format from ${url}`);
                return null;
            }
        } catch (error) {
            this.logApiError(error, endpoint);
            return null;
        }
    }

    // Function to clear chat history
    clearHistory() {
        this.chatHistory = [];
        console.log("Chat history cleared.");
    }

    // Function to generate a PDF URL
    async generatePdfUrl(chapter, start, end) {
        const endpoint = "/create_pdf_url";
        const url = new URL(`${apiUrl}${endpoint}`);
        url.searchParams.append("filename", chapter);
        url.searchParams.append("start_page", start);
        url.searchParams.append("end_page", end);

        try {
            const response = await axios.get(url.toString(), {
                headers: {
                    accept: 'application/json',
                    'x-api-key': apiKey,
                },
            });

            if (response.status === 200 && response.data.pdf_sas_url) {
                return response.data.pdf_sas_url;
            } else {
                console.error(`Error: Unexpected response format from ${url}`);
                return null;
            }
        } catch (error) {
            this.logApiError(error, endpoint);
            return null;
        }
    }

    // Function to generate a URL of an existing PDF in blob storage
    async generateUrl(blobName) {
        const endpoint = "/get_pdf_url";
        const url = new URL(`${apiUrl}${endpoint}`);
        url.searchParams.append("filename", blobName);

        try {
            const response = await axios.get(url.toString(), {
                headers: {
                    accept: 'application/json',
                    'x-api-key': apiKey,
                },
            });

            if (response.status === 200 && response.data.pdf_sas_url) {
                return response.data.pdf_sas_url;
            } else {
                console.error(`Error: Unexpected response format from ${url}`);
                return null;
            }
        } catch (error) {
            this.logApiError(error, endpoint);
            return null;
        }
    }
}

export default new AviationApi();
